<template>
  <div class="home">
    <div v-if="!isClose">
      <div class="c-left" @click="open"></div>

      <div class="c-logo"></div>
    </div>

    <div class="header"></div>

    <div id="container" class="map"></div>
    <div class="detail" v-if="isInfo">
      <div class="banner">
        <div class="txt">
          <div class="desc">{{ info1.desc }}</div>
          <p>
            容纳人数：<i>{{ info1.num }}</i>
          </p>
          <p>
            地址：<i>{{ info1.address }}</i>
          </p>
        </div>
      </div>
      <div class="fwxx">
        <h3>服务信息</h3>
        <p class="address">
          服务阵地：<i>{{ info.name }}</i>
        </p>
        <p class="time">
          服务时间：<i>{{ info.time }}</i>
        </p>
        <p class="user">
          联系人：<i>{{ info.user }}</i>
        </p>
        <p class="phone">
          联系电话：<i>{{ info.mobile }}</i>
        </p>
      </div>
      <div class="open-phone" @click="show = true">
        <span>电话咨询<i></i></span>
      </div>
    </div>
    <div class="qb" v-if="isClose" @click="close">
      <p>查看全部</p>
      <p>服务空间</p>
      <span></span>
    </div>
    <van-popup
      v-model="show"
      closeable
      round
      position="bottom"
      :style="{ height: '30%' }"
    >
      <div class="pop">
        <h5>拨打电话</h5>
        <p>{{ info.mobile }}</p>
        <a :href="tel">立即拨打</a>
      </div>
    </van-popup>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader"
// @ is an alias to /src
import { _isMobile } from "@/utils/util"
import { data } from "@/utils/data"
import { data1 } from "@/utils/data1"
export default {
  name: "Home",
  components: {},
  data() {
    return {
      map: {},
      ac: 0,
      show: false,
      list: data1,

      isInfo: false,
      mkList: [],
      mkList2: [],
      iMaker: null,
      info: {},
      info1: {},
      isClose: true,
      tel:''

    }
  },
  created() {
    this.getInfo(this.$route.query.name)
    this.initMap()
  },
  methods: {
    open() {
      this.isClose = true
      this.map.clearMap()
      this.isInfo = false
      this.$emit("close", true)
      this.addMaker()
    },
    close() {
      this.isClose = false
      this.map.clearMap()
      this.isInfo = false
      this.$emit("close", false)
      this.addMaker2()
    },
    getInfo(name) {
      data.forEach((e) => {
        if (e.name === name) {
          this.info = e
          this.tel = "tel:"+this.info.mobile
        }
      })
      data1.forEach((e) => {
        if (e.service === name) {
          this.info1 = e
        }
      })
      if (!this.info.name) {
        this.$notify({ type: "warning", message: "未找到信息" })
      }
    },
    tabCli(item, index) {
      this.ac = index
    },
    initMap() {
      AMapLoader.load({
        key: "744a8f67f97df2af0bebaf0141fb937d", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [],
      }).then(() => {
        this.map = new AMap.Map("container", {
          resizeEnable: true,
          zooms: _isMobile() ? [10, 20] : [10, 20],
          zoom: _isMobile() ? 13 : 14,
          center: [121.661819, 31.234469],
          pitch: 70, // 地图俯仰角度，有效范围 0 度- 83 度
          viewMode: "2D", // 地图模式,
        })
        this.addMaker()
      })
    },
    addMaker() {
      this.mkList = []
      // <span>${e.name}</span>
      this.list.forEach((e) => {
        let markerContent = `<div class="s-item ">
          <div class="box box1 ">
             <img src="https://img.amsos.cn/images/aixin.png"/>
            </div>
          </div>`
        let marker = new AMap.Marker({
          position: new AMap.LngLat(e.lng, e.lat),
          content: markerContent,
          anchor: "bottom-center",
          offset: new AMap.Pixel(0, -63),
        })
        marker.data = e
        this.map.add(marker)
        this.mkList.push(marker)
      })
      let _this = this

      this.mkList.forEach((e) => {
        if (e.data.service === this.$route.query.name) {
          e.hide()
          this.activeMarker(e.data)
        }
        e.on("click", function (w) {
          _this.mkList.forEach((s) => s.show())
          if (_this.iMaker) {
            _this.map.remove(_this.iMaker)
          }
          e.hide()
          _this.activeMarker(w.target.data)
        })
      })
    },
    addMaker2() {
      this.mkList2 = []
      // <span>${e.name}</span>
      this.list.forEach((e) => {
        let markerContent = `<div class="s-item ">
        <div class=" box2 ">
             <img src="${e.img[0]}"/>
              <span>${e.name}</span>
            </div>
            <div class='k-icon2'></div>
          </div>`
        let marker = new AMap.Marker({
          position: new AMap.LngLat(e.lng, e.lat),
          content: markerContent,
          anchor: "bottom-center",
          offset: new AMap.Pixel(0, -63),
        })
        marker.data = e
        this.map.add(marker)
        this.mkList2.push(marker)
      })
      let _this = this

      this.mkList2.forEach((e) => {
        // if (e.data.service === this.$route.query.name) {
        //   e.hide()
        //   this.activeMarker(e.data)
        // }
        e.on("click", function (w) {
        
          _this.mkList2.forEach((s) => s.show())
          if (_this.iMaker) {
            _this.map.remove(_this.iMaker)
          }
          e.hide()
          _this.activeMarker(w.target.data)
        })
      })
    },
    activeMarker(data) {
      this.map.setCenter([data.lng, data.lat])
      this.getInfo(data.name)
      this.info1 = data

      let markerContent = `<div class="s-item ">
      
            <div class=" box2 ">
             <img src="${data.img[0]}"/>
              <span>${data.name}</span>
            </div>
            <div class='k-icon'></div>
        </div>`
      this.iMaker = new AMap.Marker({
        position: new AMap.LngLat(data.lng, data.lat),
        content: markerContent,
        anchor: "bottom-center",
        offset: new AMap.Pixel(0, -63),
      })

      this.map.add(this.iMaker)
      this.isInfo = true
    },
  },
}
</script>
<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100vh;
}
.header {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  height: 168px;
  width: 100%;
  text-align: center;
  background: linear-gradient(
    180deg,
    rgba(232, 8, 7, 0.42) 0%,
    rgba(232, 8, 7, 0) 100%
  );
  span {
    position: relative;
    display: inline-block;
    margin-top: 58px;
    width: 752px;
    height: 72px;
    line-height: 72px;
    text-align: center;
    background: linear-gradient(
      90deg,
      rgba(243, 0, 0, 0) 0%,
      #ea0000 51%,
      rgba(226, 0, 0, 0) 100%
    );

    i {
      position: relative;
      z-index: 11;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      color: #ffffff;
    }
  }
  span::after {
    content: "";
    position: absolute;
    top: 35px;
    left: 150px;
    width: 120px;
    height: 1px;
    background: rgba(255, 255, 255, 0.6);
    z-index: 10;
  }
  span::before {
    content: "";
    position: absolute;
    top: 35px;
    right: 150px;
    width: 120px;
    height: 1px;
    background: rgba(255, 255, 255, 0.6);
    z-index: 10;
  }
}
.c-logo {
  position: fixed;
  top: 46px;
  left: 48px;
  width: 40px;
  height: 40px;
  background: url("../assets/img/logo.png") no-repeat center !important;
  background-size: cover !important;
  z-index: 999999;
}
.c-left {
  position: fixed !important;
  top: 48px;
  left: 0px;
  width: 40px;
  height: 40px;
  background: url("../assets/img/left.png") no-repeat center;
  background-size: cover;
  z-index: 999999;
}
/deep/ .s-item {
  text-align: center;
}
/deep/.box {
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
  img {
    display: inline-block;
    width: 24px;
    height: 24px;

    object-fit: cover;
  }
}

/deep/.box2 {
  white-space: nowrap;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 2px 5px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 22px 22px 22px 22px;
  border: 1px solid #ffffff;
  position: relative;

  img {
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 2px solid #cc0500;
    border-radius: 50%;
    margin-right: 8px;
    object-fit: cover;
  }
}

/deep/ .box2::after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -14px;
  bottom: -25px;
  border: 14px solid transparent;
  border-top-color: #fff;
}
/deep/ .k-icon {
  display: inline-block;
  content: "";
  width: 40px;
  height: 40px;
  background: rgba(224, 32, 31, 0.2)
    url("https://img.amsos.cn/images/aixin.png") no-repeat center;
  background-size: 20px;
  border-radius: 50%;
  margin-top: 15px;
}
/deep/ .k-icon2 {
  display: inline-block;
  content: "";
  width: 20px;
  height: 20px;
  background: url("https://img.amsos.cn/images/aixin.png") no-repeat center;
  background-size: 20px;
  margin-top: 15px;
}
/deep/ .box2 span {
  font-weight: bold;
  font-size: 12px;
  color: #333333;
  display: inline-block;
  padding-right: 5px;
}
.detail {
  position: fixed;
  width: 94%;
  margin-left: 3%;
  bottom: 12px;
  left: 0;
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 12px 12px 12px 12px;
  padding: 12px;
  box-sizing: border-box;
}
/deep/ .amap-logo {
  display: none !important;
}
/deep/ .amap-copyright {
  display: none !important;
}

.fwxx {
  padding: 12px 12px 12px 12px;
  background: #fff;
  border-top: 1px solid #f3f3f3;
  h3 {
    font-weight: 700;
    font-size: 14px;
    color: #333333;
    line-height: 16px;
  }
  p {
    font-weight: 700;
    font-size: 12px;
    color: #666666;
    margin-top: 8px;
    padding-left: 20px;
    i {
      color: #333;
    }
  }
  .desc {
    margin: 10px 0;
    font-size: 12px;
    color: #666666;
    line-height: 16px;
  }
  .address {
    background: url(../assets/img/zd.png) no-repeat left center;
    background-size: 14px;
  }
  .time {
    background: url(../assets/img/time.png) no-repeat left center;
    background-size: 14px;
  }
  .user {
    background: url(../assets/img/user.png) no-repeat left center;
    background-size: 14px;
  }
  .phone {
    background: url(../assets/img/phone.png) no-repeat left center;
    background-size: 14px;
  }
}
.banner {
  // margin-bottom: 6px;
  .txt {
    padding: 12px;
    box-sizing: border-box;
    background: #fff;
    .desc {
      font-size: 14px;
      color: #666666;
      line-height: 16px;
    }
    p {
      margin-top: 6px;
      font-weight: 500;
      font-size: 12px;
      color: #666666;
      i {
        color: #333;
      }
    }
  }
}
.open-phone {
  width: 100%;
  // height: 72px;
  background: #fff;
  span {
    display: inline-block;
    width: 100%;
    text-align: center;

    background: #e0201f;
    border-radius: 8px 8px 8px 8px;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 700;
    i {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-left: 5px;
      background: url(../assets/img/bsphone.png) no-repeat center;
      background-size: cover;
      vertical-align: middle;
    }
  }
}
.qb {
  position: fixed;
  bottom: 126px;
  right: 0;
  width: 72px;
  height: 140px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 12px 0px 0px 12px;
  padding-top: 45px;
  box-sizing: border-box;
  text-align: center;

  p {
    font-size: 12px;
    color: #ffffff;
  }
  span {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(../assets/img/right.png) no-repeat center;
    background-size: cover;
    margin-top: 8px;
  }
}
</style>
